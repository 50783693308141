import Vue from 'vue'
import Router from "vue-router"

// component

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: to => {
      const authHeaders = $cookies.get('userAuth')
      const authData = $cookies.get('userData')

      if (authHeaders) return { name: 'pos' }

      return { name: 'auth-login' }
    },
  },
  {
    path: '/pos',
    name: 'pos',
    redirect: to => { return { name: 'pos-item' } },
    component: () => import('@/components/pos/body'),
    children: [
      {
        path: '/pos/items',
        name: 'pos-item',
        component: () => import('@/views/pos/Item.vue'),
        meta: { title: 'Carada POS - Items' },
      },
      {
        path: '/pos/transactions',
        name: 'pos-transaction',
        component: () => import('@/views/pos/Transaction.vue'),
        meta: { title: 'Carada POS - Transactions' },
      },
      {
        path: '/pos/checkout',
        name: 'pos-checkout',
        component: () => import('@/views/pos/Checkout.vue'),
        meta: { title: 'Carada POS - Checkout' },
      },
      {
        path: '/pos/checkout-receipt',
        name: 'pos-checkout-receipt',
        component: () => import('@/views/pos/print/CheckoutReceipt.vue'),
        meta: { title: 'Carada POS - Checkout' },
      },
      {
        path: '/pos/checkout-invoice',
        name: 'pos-checkout-invoice',
        component: () => import('@/views/pos/print/CheckoutInvoice.vue'),
        meta: { title: 'Carada POS - Checkout' },
      },
      {
        path: '/pos/checkout-delivery-order',
        name: 'pos-checkout-delivery-order',
        component: () => import('@/views/pos/print/CheckoutDeliveryOrder.vue'),
        meta: { title: 'Carada POS - Checkout' },
      },
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    redirect: to => { return { name: 'admin-transaction' } },
    component: () => import('@/components/admin/body'),
    children: [
      {
        path: '/admin/dashboard',
        name: 'admin-dashboard',
        component: () => import('@/views/admin/Dashboard.vue'),
        meta: { title: 'Carada Admin - Dashboard' },
      },
      {
        path: '/admin/transactions',
        name: 'admin-transaction',
        component: () => import('@/views/admin/Transaction.vue'),
        meta: { title: 'Carada Admin - Transactions' },
      },
      {
        path: '/admin/inventory',
        name: 'admin-inventory',
        component: () => import('@/views/admin/Inventory.vue'),
        meta: { title: 'Carada Admin - Inventory' },
      },
      {
        path: '/admin/customers',
        name: 'admin-customer',
        component: () => import('@/views/admin/Customer.vue'),
        meta: { title: 'Carada Admin - Customers' },
      },
      {
        path: '/admin/quotations',
        name: 'admin-quotation',
        component: () => import('@/views/admin/quotation/QuotationList.vue'),
        meta: { title: 'Carada Admin - Quotations' },
      },
      {
        path: '/admin/quotations/form/:id?',
        name: 'quotation-form',
        component: () => import('@/views/admin/quotation/QuotationForm.vue'),
        meta: { title: 'Carada Admin - Quotations' },
      },
      {
        path: '/admin/quotations/:id',
        name: 'quotation-view',
        component: () => import('@/views/admin/quotation/QuotationView.vue'),
        meta: { title: 'Carada Admin - Quotations' },
      }
    ]
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
  }]

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const authHeaders = $cookies.get('userAuth')
  const authData = $cookies.get('userData')
  if (from.path.includes("/pos") || from.path.includes("/admin") && !authHeaders) return next()
  if (to.path == "/login") {
    if (authHeaders) {
      return next('/')
    } else {
      return next()
    }
  }
  if (!authHeaders) return next({ name: 'auth-login' })

  if (to.path.includes('/admin')) {
    if (authData['role'] == 'Clerk') {
      return next('/pos')
    }
  }
  return next()
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'Carada - Automotive Parts'
  })
})

export default router
